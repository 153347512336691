<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        Контактные реквизиты
        <div class="card-header-actions">
          <b-button
            v-if="$store.getters.isAdmin"
            variant="primary"
            size="sm"
            to="/contact-requisites/add"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-form-group
          id="vehicleMakeInputGroup4"
          class="form-group-el-select"
          label="Контактные реквизиты"
          label-for="vehicle-make"
        >
          <el-select-clearable
            v-model="value"
            class="form-control"
            name="vehicle-make"
            placeholder="Выберите контактные реквизиты"
            clearable
            :multiple="true"
            :filterable="true"
            :remote="true"
            reserve-keyword
            :loading="loading"
          >
            <el-option
              v-for="item in items"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            />
          </el-select-clearable>
        </b-form-group>
        <div class="fixed-buttons">
          <b-button
            type="button"
            variant="secondary"
            class="mr-2"
            :class="{'loading' : loading}"
            @click="$router.go(-1)"
          >
            Назад
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            :class="{'loading' : loading}"
            :disabled="loading"
            @click.prevent="onSubmit"
          >
            Сохранить
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import notifications from '../../components/mixins/notifications';
import {contactRequisitesList, contractorsContactRequisitesList, contractorsContactRequisitesPatch} from '../../services/api';

export default {
  name: 'ContactsForContractorEdit',
  mixins: [notifications],
  props: ['contractorId'],
  data: function() {
    return {
      loading: false,
      items: [],
      value: [],
      oldValue: [],
    };
  },
  computed: {
    changes: function() {
      const remove = this.oldValue.map((value) => {
        if (!this.value.includes(value)) {
          return value;
        }
      });
      const add = this.value.map((value) => {
        if (!this.oldValue.includes(value)) {
          return value;
        }
      });

      return {add: add, remove: remove};
    },
  },
  mounted() {
    // Запрос на получение всех контактных реквизитов для селекта выбора
    this.contactRequisitesList();
    // Запрос на получение контактных реквизитов конкретного перевозчика
    this.contractorsContactRequisitesList();
  },
  methods: {
    async contactRequisitesList() {
      this.loading = true;
      const response = await contactRequisitesList({limit: 100});
      if (response && response.status === 200) {
        this.items = response.data.items.map((requisite) => {
          return {
            value: requisite.id,
            text: requisite.name,
          };
        });
      }
      this.loading = false;
    },
    async contractorsContactRequisitesList() {
      this.loading = true;
      const response = await contractorsContactRequisitesList(this.contractorId, {
        limit: 100,
      });
      if (response && response.status === 200) {
        this.value = response.data.items.map((requisite) => {
          return requisite.id;
        });
        this.oldValue = response.data.items.map((requisite) => {
          return requisite.id;
        });
      }
      this.loading = false;
    },
    async onSubmit() {
      this.loading = true;
      const response = await contractorsContactRequisitesPatch(this.contractorId, this.changes);
      if (response && response.status === 200) {
        this.showSuccess('Контактные реквизиты присвоены');
        this.$router.push({path: '/contractor-list'});
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>
